import { t } from 'i18next'

import type { SubtypeItem as ArtSubtypeItem } from 'core/remodel/types/artSummary'
import type { SubtypeItem as BelongingSubtypeItem } from 'core/remodel/types/belongingSummary'
import { AssetType, Currency } from 'core/remodel/types/common'
import { BathroomType } from 'core/remodel/types/properties'
import type { SubtypeItem as WineSubtypeItem } from 'core/remodel/types/wineSummary'

type SubtypeItem = ArtSubtypeItem | BelongingSubtypeItem | WineSubtypeItem

type EmptyItemsOptions = {
  assetType: AssetType
  subtypeItems: SubtypeItem[] | undefined
  length?: number
  currency?: Currency
  mixedEmpty?: boolean
}

type ReturnSubtypeItem = SubtypeItem & {
  assetType: AssetType
  isEmptyState?: boolean
}

const emptyItem = ({ assetType, currency }: { assetType: AssetType; currency: Currency }): ReturnSubtypeItem => ({
  assetType: assetType,
  label: t(`AssetTypeOptions.${assetType}`),
  assetNumber: 0,
  itemNumber: 0,
  value: { currency, value: 0 },
  liability: { currency, value: 0 },
  percentage: 0,
  isEmptyState: true
})

export const getEmptyItems = ({
  assetType,
  subtypeItems,
  length = 6,
  currency = Currency.USD,
  mixedEmpty = false
}: EmptyItemsOptions): ReturnSubtypeItem[] => {
  const createEmptyItems = (type: AssetType, count: number) =>
    Array.from({ length: count }, () => emptyItem({ assetType: type, currency }))

  // no data
  if (!subtypeItems) {
    return mixedEmpty
      ? [
          ...createEmptyItems(AssetType.Art, 1),
          ...createEmptyItems(AssetType.WineAndSpirits, 1),
          ...createEmptyItems(AssetType.OtherCollectables, 4)
        ]
      : createEmptyItems(assetType, length)
  }

  const items = subtypeItems
    .map(
      (subType): ReturnSubtypeItem => ({
        assetType,
        ...subType,
        label: subType.label ?? assetType,
        isEmptyState: false
      })
    )
    .sort((a, b) => b.value.value - a.value.value)

  const fillLength = Math.max(0, length - items.length)

  // data with the same asset type
  if (!mixedEmpty) {
    return [...items, ...createEmptyItems(assetType, fillLength)]
  }

  // data with mixed asset type : used at global dashboard
  const existingTypes = new Set(items.map((item) => item.assetType))
  const missingTypes = [AssetType.Art, AssetType.WineAndSpirits].filter((type) => !existingTypes.has(type))
  const remainingEmptyCount = Math.max(0, fillLength - missingTypes.length)

  return [
    ...items,
    ...missingTypes.map((type) => emptyItem({ assetType: type, currency })),
    ...createEmptyItems(AssetType.OtherCollectables, remainingEmptyCount)
  ]
}

type EmptyRoomOptions = {
  type: 'Bedroom' | 'Bathroom'
  length?: number
}

export function getEmptyRooms({ type, length = 5 }: EmptyRoomOptions) {
  switch (type) {
    case 'Bedroom':
      return Array.from({ length }).map(() => ({
        kingBedNum: 0,
        bunkBedNum: 0,
        queenBedNum: 0,
        cotBedNum: 0,
        doubleBedNum: 0,
        couchBedNum: 0,
        singleBedNum: 1,
        position: [],
        name: t('Field.RoomName'),
        size: 0
      }))
    case 'Bathroom':
      return Array.from({ length }).map(() => ({
        bathroomType: BathroomType.Bathroom,
        position: [],
        name: t('Field.RoomName'),
        size: 0
      }))
  }
}
